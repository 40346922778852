/** @format */

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@layer components {
    .scrollbar::-webkit-scrollbar {
        width: 15px;
        height: 13px;
    }

    .scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #e5e7eb;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #797a7b;
        border-radius: 100vh;
        border: 3px solid #e5e7eb;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: #797a7b;
        height: 20px;
    }
}

.shimmer {
    background: linear-gradient(
        to right,
        #f0f0f0 0%,
        #e0e0e0 20%,
        #f0f0f0 40%,
        #f0f0f0 100%
    );
    background-size: 200% 100%;
}

@layer utilities {
    /* Chrome, Safari and Opera */
    .scrollbar-hidden::-webkit-scrollbar {
        display: none;
    }

    .scrollbar-hidden {
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE and Edge */
    }
}
